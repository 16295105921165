import React, { useRef, useState } from 'react';
import PhoneInput, {
  isPossiblePhoneNumber,
} from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/logo.png';
import api from '../../services/api';
import '../../styles.css';
import './styles.css';

export default function Booking() {
  const [availableSlots, setAvailableSlots] = useState([]);
  const [wrongDate, setWrongDate] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const name = useRef();
  const surname = useRef();
  const phone = useRef();
  const date = useRef();
  const time = useRef();
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const res = await api.post('/booking', {
        date: time.current,
        phone: phone.current,
        name: `${name.current} ${surname.current}`,
      });
      history.push(`/agendamento/${res.data.id}`);
    } catch (err) {
      if (err.status === 406) {
        return alert(
          'Este horário não está mais disponível. Por favor selecione outro horário e tente novamente.'
        );
      }
      alert(
        'Erro ao agendar horário. Se o erro persistir, por favor entre em contato.'
      );
      console.error(err);
    }
  }

  function validateInputs() {
    if (
      !phone.current ||
      phone.current.length !== 14 ||
      !isPossiblePhoneNumber(phone.current)
    ) {
      return setSubmitEnabled(false);
    }
    if (!name.current || name.current.length < 4) {
      return setSubmitEnabled(false);
    }
    if (!surname.current || surname.current.length < 3) {
      return setSubmitEnabled(false);
    }
    if (!date.current) {
      return setSubmitEnabled(false);
    }
    if (!time.current) {
      return setSubmitEnabled(false);
    }
    return setSubmitEnabled(true);
  }

  async function handleDateChanged(e) {
    setWrongDate(false);
    setSubmitEnabled(false);
    setAvailableSlots([]);
    date.current = e.target.value;
    const response = await api.get('/bookings/available', {
      params: {
        ISODate: `${date.current}T00:00:00.000`,
      },
    });
    if (response.status === 200) {
      if (response.data.errorCode === 'no-times') {
        return alert(
          'Não temos nenhum horário disponível para esta data. Por favor selecione outra data.'
        );
      }
      if (response.data.errorCode === 'closed-date') {
        return alert('Dia fechado, verifique o dia seguinte.');
      }
      if (response.data.errorCode === 'no-working') {
        return alert(
          'Não funcionamos na data selecionada. Por favor selecione outra data.'
        );
      }
      time.current = response.data[0];
      setAvailableSlots(response.data);
      validateInputs();
    } else {
      setWrongDate(true);
      setSubmitEnabled(false);
      alert('Ocorreu algum erro, tente novamente mais tarde.');
    }
  }

  function handlePhoneChanged(value) {
    phone.current = value;
    validateInputs();
  }

  return (
    <div>
      <header>
        <div className='header-1'>
          <div className='logo'>
            <img src={logo} width='250px' alt='' />
          </div>
        </div>
      </header>
      <main>
        <form onSubmit={handleSubmit}>
          <h3>AGENDE O SEU HORÁRIO</h3>
          <input
            onChange={(e) => {
              name.current = e.target.value;
              console.log(name.current);
              validateInputs();
            }}
            placeholder='Digite o seu nome'
            type='text'
          />

          <input
            onChange={(e) => {
              surname.current = e.target.value;
              console.log(surname.current);
              validateInputs();
            }}
            placeholder='Digite o seu sobrenome'
            type='text'
          />

          <PhoneInput
            placeholder='Digite seu número de telefone'
            country='BR'
            onChange={handlePhoneChanged}
          />

          <div id='datePickers'>
            <input
              id='date'
              type='date'
              slot=''
              onChange={handleDateChanged}
              style={
                wrongDate
                  ? {
                      borderColor: '#f44',
                      borderWidth: 2,
                    }
                  : {}
              }
            />
            <select
              id='time'
              onChange={(e) => {
                time.current = e.target.value;
                validateInputs();
              }}>
              {availableSlots.map((s) => {
                const date = new Date(s);
                return (
                  <option value={s} key={s}>
                    {String(date.getHours()).padStart(2, '0')}:
                    {String(date.getMinutes()).padStart(2, '0')}
                  </option>
                );
              })}
            </select>
          </div>
          <button
            type='submit'
            disabled={!submitEnabled}
            style={submitEnabled ? {} : { backgroundColor: '#a1e6c4' }}>
            AGENDAR
          </button>
          {/* <button
            className='bookingButton'
            onClick={() => history.push('/cancelamento')}>
            <h5>Visualizar e cancelar agendamento</h5>
          </button> */}
          <h5 style={{ fontWeight: 800, fontSize: 13, color: `#B22222` }}>
            Pedimos a gentileza de chegar 5 minutos antes!<br></br>Em caso de
            atraso seu horário será cancelado.
          </h5>
        </form>
      </main>
    </div>
  );
}
